"use client";
import React, { useRef, useState } from "react";
import { BiCart } from "react-icons/bi";
import { cn } from "@/lib/utils";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { AiFillPlusCircle, AiOutlineDelete } from "react-icons/ai";
import { CartItemTypes } from "@/types/additional-types";
import { Button } from "../ui/button";
import Link from "next/link";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Label } from "../ui/label";
import { Separator } from "../ui/separator";
import { useToast } from "../ui/use-toast";
import { CgSpinner } from "react-icons/cg";
import { useReactToPrint } from "react-to-print";
import { ProductCategoryType } from "@/app/admin/store/admin-products";
import { Json } from "@/lib/database.types";
import { removeCartItem } from "@/app/actions/cart-actions";
import PDFGenerator from "@/app/pdf/pdf-gen";
import { ProductImageTypes } from "@/types/products-types";

const NavCart = ({
  cartItems,
  iconOnly,
  productsArr,
}: {
  cartItems: CartItemTypes;
  iconOnly?: boolean;
  productsArr: {
    additional_data: Json;
    id: string;
    product_images: ProductImageTypes;
  }[];
}) => {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const isCartEmpty = Array.isArray(cartItems) ? cartItems.length === 0 : true;

  const deleteItem = async (id: string) => {
    setLoading(true);
    try {
      removeCartItem({ itemId: id });

      toast({
        title: "Item removed from cart",
        variant: "destructive",
      });
    } catch (error) {
      console.error("Failed to remove item");
      toast({
        title: "Failed to remove item from cart",
        variant: "destructive",
      });
    }

    setLoading(false);
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Cart Quatation",
    pageStyle: "margin:1rem",
  });

  const items = cartItems
    ? cartItems.flatMap((i) =>
        i.selected.map((d) => ({
          id: i.id,
          name: i.name,
          category: i.category as ProductCategoryType,
          stock: d,
        })),
      )
    : [];

  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          "flex h-9 flex-row items-center justify-center gap-2 whitespace-nowrap rounded-md border-none bg-background/60 px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-background/90 hover:text-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
          iconOnly && "size-9 p-0",
        )}
      >
        <BiCart />
        {!iconOnly && <span>Cart</span>}
      </PopoverTrigger>
      <PopoverContent className="w-auto min-w-[80vw] max-w-[95vw] sm:min-w-96">
        {items && productsArr && (
          // <CartPDF
          //   cartItems={cartItems}
          //   componentRef={componentRef}
          //   build={items}
          //   productArr={productsArr}
          // />
          <PDFGenerator
            pdfType="cart"
            build={items}
            componentRef={componentRef}
            cartItems={cartItems}
            productArr={productsArr}
          />
        )}

        <Label className="flex flex-row items-center justify-between gap-4">
          <span>Cart Items</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="inline-flex h-9 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium text-primary underline-offset-4 transition-colors hover:underline focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                <Link href="/build">Build</Link>
              </TooltipTrigger>
              <TooltipContent>
                <span>Build Your Pc With Your Cart Items</span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </Label>
        <Separator />
        <ScrollArea className="h-64 pr-2">
          <ScrollBar />
          <div className="flex h-full w-full flex-col items-start justify-center gap-1.5 overflow-y-scroll pt-4">
            {isCartEmpty ? (
              <span className="flex flex-row items-center justify-center gap-2 px-3 py-4 text-sm">
                <AiFillPlusCircle /> Add Items To Cart
              </span>
            ) : (
              cartItems.map(
                (c, index) =>
                  c.selected &&
                  c.selected.map((s) => (
                    <Button
                      variant="secondary"
                      className="h-auto w-full flex-nowrap justify-between gap-4"
                      key={index}
                    >
                      <span className="whitespace-normal text-left">
                        {c.name ?? c.id}
                        {s.varient} - {s.price}Rs - {c.amount || 1}
                      </span>
                      <AlertDialog>
                        <AlertDialogTrigger>
                          {loading ? (
                            <CgSpinner className="animate-spin" />
                          ) : (
                            <AiOutlineDelete />
                          )}
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Are you absolutely sure?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This action cannot be undone. This will
                              permanently delete The Item
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => deleteItem(c.id)}>
                              Continue
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </Button>
                  )),
              )
            )}
          </div>
        </ScrollArea>
        <Button
          onClick={handlePrint}
          variant="outline"
          disabled={cartItems !== null && cartItems.length === 0}
          className="mt-4 w-full justify-start"
          size="sm"
        >
          Genarate PDF
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default NavCart;
